import React, { useEffect, useState } from 'react'
import { ZoomMtg } from '@zoomus/websdk';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OpenSession.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import axios from "axios"
import {useNavigate, useParams} from "react-router-dom";


ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const OpenSession = () => {
   
    const [userInfo, setUserInfo] = useState([])
    const [event,setEvent]=useState([]);
    const [invalid,setInvalid]=useState(0);


    const Img_style={
        textAlign:"center",
        margin:"auto",
        width:"250px",
    };
    const params=useParams();
    let event_id=params.id;
    const navigate=useNavigate();

    useEffect(() => {
      get_event_details();
    }, [event_id])
    const handleChange=(e)=>{
        const name=e.target.name;
        const value=e.target.value;
        setUserInfo({...userInfo,[name]:value});
    }

    const get_event_details=async()=>{
      const form_data = { 
        user_id:1,
        event_id:event_id,    
      };      
      const live_url='https://iasscore.in/api/mentorbox/event_details_for_zoom';

      axios.post(live_url, form_data,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        console.log(response);    
        if(response.data.details.details){
          setEvent(response.data.details.details)  
        }else{
          setInvalid(1);
        }    
      }, (error) => {
        console.log(error);        
      }); 
    }

    useEffect(() => {
      if(invalid){
        navigate('/invalid');
      }else{
      
      }
     }, [invalid])
     

    const open_session_join=(e)=>{
        e.preventDefault();
        if(userInfo['name'].trim() && userInfo['mobile'].trim()){
            console.log(userInfo);
              const form_data = { 
                name:userInfo['name'],
                mobile:userInfo['mobile'],
                // email:userInfo['email'],
                event_id:event_id,    
              };      
              const live_url='https://iasscore.in/api/mentorbox/open_session_user_info_insert';
        
              axios.post(live_url, form_data,{
                headers: {
                  'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
                }
              })
              .then((response) => {  
                // console.log(response);    
                 if(response.data.details){
                    // console.log(response.data.details);
                    getSignature();
                 }
              }, (error) => {
                console.log(error);        
              });
        }else{
            alert('Name and mobile Required');
        }        
    }

      var signatureEndpoint = 'https://conapi.iasscore.in'
      var sdkKey = '88Itp1a0NGJ7VTaVW3ZYpX6VLbCwhAdyD9Di';
      var meetingNumber = event.event_code
      var role = 0
      var leaveUrl = 'https://connect.iasscore.in/thank-you'
      var userName = userInfo['name']
      // var userEmail = userInfo['email']
      var passWord = event.created_by
      // pass in the registrant's token if your meeting or webinar requires registration. More info here:
      // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
      // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
      var registrantToken = ''
      function getSignature() {    
        fetch(signatureEndpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            meetingNumber: meetingNumber,
            role: role
          })
        }).then(res => res.json())
        .then(response => {
          startMeeting(response.signature)
        }).catch(error => {
          console.error(error)
        })
      }

      function startMeeting(signature) {
        // console.log('********************************');
        // console.log(userName);
        // console.log(userInfo);
        // console.log('********************************');

        document.getElementById('zmmtg-root').style.display = 'block'
        console.warn('signature',signature);
        ZoomMtg.init({
          leaveUrl: leaveUrl,
          success: (success) => {
            console.log(success)
            ZoomMtg.join({
              signature: signature,
              meetingNumber: meetingNumber,
              userName: userName,
              sdkKey: sdkKey,
              userEmail: '',//userEmail
              passWord: passWord,
              tk: registrantToken,
              success: (success) => {
                console.log(success)
              },
              error: (error) => {
                console.log(error)
              }
            })
          },
          error: (error) => {
            console.log(error)
          }
        })
      }
  return (
    <div style={{backgroundColor:"#d9d9d9"}}>
        <div className='row' style={{display:"flex",justifyContent:'center',alignItems:'center',height:"100vh"}}>
            <Col md={4}>
            </Col>
            <Col md={4}>
                <div className='card p-4 mt-3'>
                <img src="https://iasscore.in/assets/img/iasscore-logo.png" alt="" className='img-fluid' style={Img_style}  />
                <Form onSubmit={open_session_join}>
                    <h3 className='text-center text-danger'>{event.event_title}</h3>
                    <br/>
                    <Form.Group className="mb-3" controlId="formBasic">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Full Name" name="name" onChange={handleChange} required/>
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasic">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" placeholder="Enter Email" name="email" onChange={handleChange} required/>
                    </Form.Group>
                    */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text" pattern="[6789][0-9]{9}" placeholder="Mobile Number" name="mobile" onChange={handleChange} required/>
                    </Form.Group> 
                    <div className='text-center'>
                    <Button variant="danger" type="submit">
                        CONTINUE
                    </Button>
                    </div>
                </Form>
                </div>        
            </Col>
            <Col md={4}>
            </Col>
        </div>
    </div>
  )
}

export default OpenSession